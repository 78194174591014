<template>
  <div @click.capture="$emit('click')">
    <editor
      ref="tinyRef"
      v-model="content"
      :id="getEditorId"
      :init="{
        forced_root_block: 'P',

        height: getHeight,
        resize: false,
        menubar: false,
        branding: false,
        elementpath: false,
        automatic_uploads: false,
        image_description: false,
        image_dimensions: true,
        /*
         * 이 옵션을 사용하면 MS Word 및 유사한 Office 제품군 제품의 내용을 붙여넣을 때 유지할 스타일을 지정할 수 있습니다.
         * 이 옵션은 공백으로 구분된 CSS 스타일 이름 목록으로 설정하거나, 모든 스타일을 유지하려면 all 로 설정할 수 있습니다.
         */
        paste_retain_style_properties: 'all',
        /*
         * 이 옵션은 붙여넣은 내용에서 이미지(인라인 이미지) SRC 가 data:url 를 허용 할지 결정합니다.
         */
        paste_data_images: useImagePaste,
        // image 붙여넣기 blob사용 X
        images_dataimg_filter: function(img) {
          return img.hasAttribute('internal-blob');
        },
        base_url: '/js',
        image_prepend_url: `${imageUrl}/`,
        file_picker_types: 'image',
        file_picker_callback: callback,
        plugins: [
          'lists image autolink hr charmap fullscreen link preview',
          'table quickbars paste code'
        ],
        checkTable: checkTable,

        quickbars_insert_toolbar: false,
        quickbars_selection_toolbar: this.selectionToolbar,
        toolbar: this.toolbar,
        toolbar_location: this.toolbarLocation,

        font_formats: $t('common.font-family'),
        content_style:
          'body { font-family: 나눔고딕; line-height: 1.5; } p { margin: 0px; }',
        table_default_styles: {
          'word-break': 'break-all',
          'border-collapse': 'collapse',
          width: '100%'
        }
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
/* 툴바 버튼 */
::v-deep .tox .tox-tbtn {
  border-radius: 0px;
  &:hover,
  &:active,
  &:focus {
    background: #eeeeee;
  }
  /* 툴바 버튼 아이콘 컬러 */
  .tox-icon svg {
    fill: rgba(0, 0, 0, 0.54);
  }
}
/* 툴바 메뉴 */
.tox ::v-deep .tox-tiered-menu {
  border: 1px solid red;
}
::v-deep .tox .tox-menu {
  border-width: 0px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4 px;
}
// 에디터 하단 상자 제거
::v-deep .tox.tox-tinymce {
  .tox-statusbar {
    display: none;
  }
}
</style>

<!-- 원본
        toolbar:
          'fontselect fontsizeselect formatselect | \
           bold italic strikethrough underline forecolor backcolor lineheight | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | \
           image charmap link table hr quickbars | \
           removeformat preview fullscreen | \
           undo redo'
-->

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";

import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/bbcode";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/fullpage";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/hr";
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/legacyoutput";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/pagebreak";
require("./paste/plugin.js");
import "tinymce/plugins/preview";
import "tinymce/plugins/print";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/tabfocus";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/toc";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";

import Editor from "@tinymce/tinymce-vue";
import html2canvas from "html2canvas";
import { mapActions, mapGetters, mapMutations } from "vuex";
import i18n from "@/_locales";

export default {
  components: { editor: Editor },
  props: {
    useFontSelect: {
      default: true,
      type: Boolean
    },
    useFontSizeSelect: {
      default: true,
      type: Boolean
    },
    useLineHeight: {
      default: true,
      type: Boolean
    },
    useAlign: {
      default: true,
      type: Boolean
    },
    useDent: {
      default: true,
      type: Boolean
    },
    useImage: {
      default: true,
      type: Boolean
    },
    useImagePaste: {
      default: true,
      type: Boolean
    },
    useFullScreen: {
      default: true,
      type: Boolean
    },
    useUndoRedo: {
      default: true,
      type: Boolean
    },
    useCode: {
      default: false,
      type: Boolean
    },
    toolbarLocation: {
      default: "top",
      type: String
    },
    callback: {
      default: () => {},
      type: Function
    }
  },
  destroyed() {
    this.RESET_EDITOR();
  },
  computed: {
    ...mapGetters("editor", ["getEditorId", "getContent", "getHeight"]),
    toolbar: function() {
      let items = "";
      items += this.useFontSelect ? "fontselect " : "";
      items += this.useFontSizeSelect ? "fontsizeselect " : "";
      items += this.useFontSelect || this.useFontSizeSelect ? " | " : "";

      items += "bold italic strikethrough underline forecolor backcolor ";
      items += this.useLineHeight ? "lineheight | " : " | ";

      items += this.useAlign ? "alignleft aligncenter alignright | " : "";
      items += "bullist numlist ";
      items += this.useDent ? "outdent indent | " : " | ";

      items += this.useImage ? "image " : "";
      items += "link table hr quickbars | ";

      items += this.useCode ? "code | " : "";

      items += this.useUndoRedo ? "undo redo | " : "";
      items += this.useFullScreen ? "fullscreen" : "";

      return items;
    },
    selectionToolbar() {
      let items = "bold italic underline | quicklink ";
      items += this.useLineHeight ? "lineheight" : "";
      return items;
    },
    content: {
      get() {
        return this.getContent;
      },
      set(value) {
        this.SET_CONTENT(value);
      }
    },
    imageUrl() {
      return window.location.origin;
    }
  },
  watch: {
    getHeight(height) {
      this.$refs.tinyRef.$parent.$el.querySelector(
        ".tox.tox-tinymce"
      ).style = `height: ${height}px`;
    }
  },
  methods: {
    ...mapActions("positioningMenu", ["positioningMenu"]),
    ...mapMutations("editor", ["SET_CONTENT", "RESET_EDITOR"]),
    checkTable(e, next) {
      const html = e.clipboardData.getData("text/html");
      const doc = new DOMParser().parseFromString(html, "text/html");
      const [htmlEl] = doc.getElementsByTagName("html");
      const [google] = doc.getElementsByTagName("google-sheets-html-origin");

      // ms - xmlns:o="urn:schemas-microsoft-com:office:office"
      // google - google-sheets-html-origin
      const isOffice =
        htmlEl.getAttribute("xmlns:o") ==
          "urn:schemas-microsoft-com:office:office" || google;

      if (!isOffice) return next();

      const {
        top: iframeElementTop,
        left: iframeElementLeft
      } = window.tinymce.activeEditor.iframeElement.getBoundingClientRect();
      const {
        top: selectionTop,
        left: selectionLeft
      } = window.tinymce.activeEditor.selection.getBoundingClientRect();
      this.positioningMenu({
        x: iframeElementLeft + selectionLeft,
        y: iframeElementTop + selectionTop,
        itemList: [
          {
            label: i18n.t("common.5"),
            func: () => {
              const div = document.createElement("div");
              div.style.display = "inline-block";
              div.innerHTML = html;
              document.body.appendChild(div);
              html2canvas(div).then(function(canvas) {
                window.tinymce.activeEditor.selection.setContent(
                  `<img src="${canvas.toDataURL()}" />`
                );
                window.tinymce.activeEditor.focus();
              });
              document.body.removeChild(div);
            }
          },
          {
            label: i18n.t("common.6"),
            func: () => {
              const [t] = doc.getElementsByTagName("table");
              if (t) t.border = "1";
              next({ "text/html": new XMLSerializer().serializeToString(doc) });
            }
          }
        ]
      });
    }
  }
};
</script>
