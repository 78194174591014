var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"!click":function($event){return _vm.$emit('click')}}},[_c('editor',{ref:"tinyRef",attrs:{"id":_vm.getEditorId,"init":{
      forced_root_block: 'P',

      height: _vm.getHeight,
      resize: false,
      menubar: false,
      branding: false,
      elementpath: false,
      automatic_uploads: false,
      image_description: false,
      image_dimensions: true,
      /*
       * 이 옵션을 사용하면 MS Word 및 유사한 Office 제품군 제품의 내용을 붙여넣을 때 유지할 스타일을 지정할 수 있습니다.
       * 이 옵션은 공백으로 구분된 CSS 스타일 이름 목록으로 설정하거나, 모든 스타일을 유지하려면 all 로 설정할 수 있습니다.
       */
      paste_retain_style_properties: 'all',
      /*
       * 이 옵션은 붙여넣은 내용에서 이미지(인라인 이미지) SRC 가 data:url 를 허용 할지 결정합니다.
       */
      paste_data_images: _vm.useImagePaste,
      // image 붙여넣기 blob사용 X
      images_dataimg_filter: function(img) {
        return img.hasAttribute('internal-blob');
      },
      base_url: '/js',
      image_prepend_url: (_vm.imageUrl + "/"),
      file_picker_types: 'image',
      file_picker_callback: _vm.callback,
      plugins: [
        'lists image autolink hr charmap fullscreen link preview',
        'table quickbars paste code'
      ],
      checkTable: _vm.checkTable,

      quickbars_insert_toolbar: false,
      quickbars_selection_toolbar: this.selectionToolbar,
      toolbar: this.toolbar,
      toolbar_location: this.toolbarLocation,

      font_formats: _vm.$t('common.font-family'),
      content_style:
        'body { font-family: 나눔고딕; line-height: 1.5; } p { margin: 0px; }',
      table_default_styles: {
        'word-break': 'break-all',
        'border-collapse': 'collapse',
        width: '100%'
      }
    }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }